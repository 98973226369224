.homepage-banners {
    background-color: #9e1d22;
    padding-top: 60px;
    padding-bottom: 60px;

    @media (max-width: 992px) {
        padding-bottom: 40px;
    }

    .banner-item {
        text-align: center;

        @media (max-width: 992px) {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            position: relative;
            display: inline-block;
        }

        .banners-title {
            font-family: font(main);
            font-size: 19px;
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            width: 80%;
            position: absolute;
            bottom: 20px;
            left: 20px;
            text-align: left;
        }
    }
}